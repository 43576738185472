<script setup lang="ts">
import { VueFinalModal } from "vue-final-modal";

defineProps<{
  title?: string;
}>();

defineEmits<(e: "update:modelValue", value: boolean) => void>();
</script>

<template>
  <VueFinalModal
    teleport-to="#teleports"
    class="modal"
    :content-class="{ 'modal-content': true }"
    overlay-transition="vfm-fade"
    content-transition="vfm-fade"
    data-lenis-prevent
    @closed="$emit('update:modelValue', false)"
  >
    <h1 v-if="title">
      {{ title }}
    </h1>

    <slot />
    <div
      class="modal-close"
      aria-label="Close"
      @click="$emit('update:modelValue', false)"
    >
      <NuxtIcon
        name="cross"
        class="block"
      />
    </div>
  </VueFinalModal>
</template>

<style lang="scss">
.modal {
  .modal-content {
    @apply relative bg-white rounded-lg w-full max-w-[80%] min-h-40 max-h-[92%] p-6 overflow-auto;
  }

  .modal-close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;

    svg {
      @apply w-6 h-6;
    }
  }

  .dark .modal-content {
    @apply bg-charcoal;
  }
}
</style>
